import * as React from 'react';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';
import * as Styled from './circleLinkStyles';

const CircleLink = (props) => {
  return props?.anchorLink ? (
    <Styled.AnchorStyles href={isExternalUrlHref(props?.anchorLink)? props?.anchorLink :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + props?.anchorLink)}>
      <Styled.ModalImage
        src={props?.imageSource}
        alt={props?.altText}
      ></Styled.ModalImage>
      <Styled.HeaderText>{props?.headerText}</Styled.HeaderText>
      <Styled.ParaText>{props?.paraText}</Styled.ParaText>
    </Styled.AnchorStyles>
  ) : (
    <></>
  );
};

export default CircleLink;
